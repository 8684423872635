<template>
  <div>
    <data-sync-state v-model="dataSynchronized" data="de membres du staff présents" @refresh="refresh"/>

    <b-row>
      <b-col>
        <b-check v-model="federalRollNumberVisible" switch>Afficher les n° de licence</b-check>
      </b-col>
      <b-col>
        <b-check v-model="capabilitiesVisible" switch>Afficher les compétences</b-check>
      </b-col>
    </b-row>
    <detailed-staff-list
            :items="staff"
            :canSelect="competition.COMPETITION_TYPE.isPartOfCdfCva"
            :showRollnumber="federalRollNumberVisible"
            :showCapabilities="capabilitiesVisible"
            :canAdd="!competition.COMPETITION_TYPE.isPartOfCdfCva || competition.isOpen && false"
            :canEdit="!competition.COMPETITION_TYPE.isPartOfCdfCva || competition.isOpen && false"
            :canRemove="!competition.COMPETITION_TYPE.isPartOfCdfCva || competition.isOpen && false"
            @selectionChanged = "staffMemhers_selectionChanged"
    ></detailed-staff-list>
  </div>
</template>

<script>
import {  RST_COMPETITION_STAFF } from "@/LocalDB";

import ModelViewsHelper from "@/services/store/models/ModelViews";
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import DetailedStaffList from '@/components/DetailedStaffList';
import DataSyncState from '@/components/Utilities/DataSyncState.vue';

export default{
  model:{
    prop: 'competition',
  },
  props:{ competition: { type:Object, required:true }},
  components: {  DetailedStaffList, DataSyncState /*ModelViewsHelper,*/ },
  computed:{
    competition_id(){
      return this.competition ? this.competition.id : null;
    },
    staff(){
      return ModelViewsHelper.getDetailedStaffListItems(this.competition.competition_type, this.competition.scope,this.competition.year, null)
                              .filter(item => item.isFederalActive)
                              .map(item => {
                                var st = RST_COMPETITION_STAFF.query().where('competition_id', this.currentCompetition.id).where('staff_id', item.id).first();
                                if(st != null){
                                    item.isSelected = true;
                                    item.cannotBeSelected = st.hasAction;
                                }
                                return item;
                            });
    },
  },
  data(){
    return {
      federalRollNumberVisible: true,
      capabilitiesVisible:true,
      dataSynchronized: null ,
    }
  },
  methods:{
    staffMemhers_selectionChanged(element){
      this.$showRefresher()
        .then(() => {
          CompetitionsConfigurationHelper.toggleStaffMemberPresence(this.currentCompetition_id, element.id);
          this.toastSaved();
          this.$hideRefresher();
        });
    },
    async refresh(){
      await this.$showRefresher();
      this.dataSynchronized = await CompetitionsConfigurationHelper.refreshStaffMembers(this.competition_id);
      /*this.fields = RST_FIELD.query()
        .where("competition_id", this.competition_id)
        .orderBy("field_number")
        .get()
        .map(item => {
          return { field: item, isRemovable: !item.isMain, canChangeHotState : false }
        });*/
      console.log('StaffmembersTab.refresh => revoir le chargement de la liste');
      this.$hideRefresher();
    }
  },
  async mounted(){
    console.log('StaffmembersTab mounted');
    await this.refresh();
  },
}
</script>
